import React from "react";
import logo from "../assets/logo.png";
import { useAccount } from "wagmi";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { gameAddress } from "../constants";

const Navbar = () => {
  const { address } = useAccount();

  return (
    <nav className="navbar flex items-center justify-between px-4 py-2  text-white shadow-lg transition-all ease-in-out duration-200 hover:shadow-2xl">
      <img src={logo} alt="Logo" className="w-18 h-16" />
      <div className="flex space-x-2 md:space-x-4">
        <a
          href="#"
          target="_blank"
          rel="noopener noreferrer"
          className="font-pixel px-2 md:px-4 py-2 text-xs md:text-base font-semibold bg-[#1A3449] border-[#FBBB48] border-2 rounded hover:bg-gray-700 active:bg-gray-900 active:scale-95 transition transform duration-200 ease-in-out shadow-lg"
        >
          Leaderboard
        </a>
        <a
          href={`https://app.camelot.exchange/?token2=${gameAddress}`}
          target="_blank"
          rel="noopener noreferrer"
          className="font-pixel px-2 md:px-4 py-2 text-xs md:text-base font-semibold bg-[#1A3449] border-[#FBBB48] border-2 rounded hover:bg-gray-700 active:bg-gray-900 active:scale-95 transition transform duration-200 ease-in-out shadow-lg"
        >
          Buy Now
        </a>
        <div className="font-pixel">
          <ConnectButton className="border-[#FBBB48] border-2 " />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
