import React from "react";
import twitter from "../assets/twitter.png";
import tg from "../assets/tg.png";
import eth from "../assets/eth.png";
import { gameAddress } from "../constants";

const Footer = () => {
  return (
    <footer className=" text-white p-4 flex justify-center space-x-4">
      <a
        href="https://twitter.com/PJ_Interstellar"
        target="_blank"
        rel="noopener noreferrer"
        className="hover:text-gray-400"
      >
        <img width={40} src={twitter} />
      </a>

      <a
        href="https://t.me/Portal_Interstellar"
        target="_blank"
        rel="noopener noreferrer"
        className="hover:text-gray-400"
      >
        <img width={40} src={tg} />
      </a>

      <a
        href={`https://arbiscan.io/token/` + gameAddress}
        target="_blank"
        rel="noopener noreferrer"
        className="hover:text-gray-400"
      >
        <img width={40} src={eth} />
      </a>
    </footer>
  );
};

export default Footer;
