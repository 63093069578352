import React, { useCallback } from "react";
import interstellarAbi from "./abi.json";
import { useAccount } from "wagmi";
import { ethers } from "ethers";
import { gameAddress, provider } from "../constants";

const UseGame = () => {
  const { address } = useAccount();
  const contract = new ethers.Contract(gameAddress, interstellarAbi, provider);

  async function getBlockTimestamp(blockNumber) {
    try {
      const block = await provider.getBlock(blockNumber);

      console.log(block);
      return block.timestamp;
    } catch (error) {
      console.error("Error fetching getblock:", error);
      throw error;
    }
  }

  const getUserInfo = useCallback(async () => {
    const knowDeath = await contract.knowDeath(address);
    const isDead = await contract.isDead(address);
    const userDataPvp = await contract.userDataPvp(address);

    const tokenContract = new ethers.Contract(
      gameAddress,
      interstellarAbi,
      provider
    );

    const balance = await tokenContract.balanceOf(address);

    const balanceInEther = ethers.utils.formatEther(balance);

    return {
      knowDeath: await getBlockTimestamp(knowDeath.toNumber()),
      isDead: isDead,
      userDataPvp: userDataPvp,
      balance: balanceInEther,
    };
  }, [interstellarAbi]);

  return {
    getUserInfo,
  };
};

export default UseGame;
