export function getActionText(actionCode) {
  switch (actionCode) {
    case 1:
      return "The user is now immortal";
    case 2:
      return "Reduce time before die of a random holder";
    case 3:
      return "Increase time before die";
    case 4:
      return "Double your bet";
    case 5:
      return "Reduce time before die";
    case 6:
      return "Reduce time before die of a random holder";
    case 7:
      return "Nothing happens";
    default:
      return "not play";
  }
}

export function timestampToDate(timestamp) {
  const date = new Date(timestamp * 1000);
  const formattedDate =
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2) +
    " " +
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2) +
    ":" +
    ("0" + date.getSeconds()).slice(-2);
  return formattedDate;
}
