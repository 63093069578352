import "./App.css";
import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar";

import "@rainbow-me/rainbowkit/styles.css";

import {
  getDefaultConfig,
  RainbowKitProvider,
  lightTheme,
} from "@rainbow-me/rainbowkit";

import { arbitrum } from "wagmi/chains";
import "firebase/database";
import Game from "./pages/game/page";
import { injected, metaMask, safe, walletConnect } from "wagmi/connectors";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider, http } from "wagmi";
import Footer from "./components/Footer";

const projectId = "4e94c20e6132bf42c55ee4f6793e9f9d";

const config = getDefaultConfig({
  appName: "app",
  projectId: projectId,
  autoConnect: true,
  chains: [arbitrum],
  connectors: [injected(), walletConnect({ projectId }), metaMask(), safe()],
  transports: {
    [arbitrum.id]: http(),
  },
});

const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider
            locale="en-US"
            theme={lightTheme({
              accentColor: "black",
              borderRadius: "small",
              fontStack: "rounded",
              overlayBlur: "small",
            })}
          >
            <Navbar />
            <Game />
            <Footer />
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </div>
  );
}

export default App;
