import Navbar from "../../components/Navbar";
import play from "../../assets/play.png";
import escapeDead from "../../assets/escapeDeath.png";

import { useState, useEffect } from "react";
import UseGame from "../../hooks/useGame";
import { useAccount } from "wagmi";

import interstellarAbi from "../../hooks/abi.json";
import { ethers } from "ethers";
import { useWriteContract } from "wagmi";
import { timestampToDate, getActionText } from "../../utils";
import { gameAddress } from "../../constants";

export default function Component() {
  const [userInfo, setUserInfo] = useState();

  const [tokenPrice, setTokenPrice] = useState();

  const [loading, setLoading] = useState(true);
  const { getUserInfo } = UseGame();
  const { data: hash, isPending, writeContract } = useWriteContract();
  const { address } = useAccount();

  const rules = [
    " 1: Use some fuel to engage a PvP battle against another player.",
    " 2: If you win, you'll either have more tokens or more time.",
    " 3: Help others",
  ];

  const [erc20Address, setErc20Address] = useState("");
  const [amount, setAmount] = useState("");
  const maxAmount = userInfo?.balance;

  const verifyAmount = (inputAmount, maxValue) => inputAmount <= maxValue;

  const handleSubmit = (event) => {
    event.preventDefault();
    const inputAmount = parseFloat(amount);
    if (isNaN(inputAmount) || !verifyAmount(inputAmount, maxAmount)) {
      alert("Amount is invalid or exceeds the maximum limit.");
      return;
    }

    writeContract({
      address: gameAddress,
      abi: interstellarAbi,
      functionName: "transfer",
      args: [
        erc20Address,
        ethers.utils.parseEther(inputAmount.toString()).toString(),
      ],
    });
  };

  const handleSetMax = () => {
    setAmount(maxAmount.toString());
  };

  const fetchTokenPrice = async () => {
    const url = `https://api.dexscreener.io/latest/dex/tokens/${gameAddress}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data && data.pairs && data.pairs.length > 0) {
        setTokenPrice(data.pairs[0].priceUsd);
      }
    } catch (error) {
      console.error("Error fetching token price:", error);
    }
  };

  const handleAction = async (functionName) => {
    try {
      writeContract({
        address: gameAddress,
        abi: interstellarAbi,
        functionName: functionName,
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const updateOnDeathChange = async () => {
      if (address) {
        setLoading(true);
        try {
          const userI = await getUserInfo();
          setUserInfo(userI);
        } catch (error) {
          console.error("Error fetching user info:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchTokenPrice();
    updateOnDeathChange();
  }, [address, getUserInfo, userInfo?.isDead]);

  return (
    <>
      {address && userInfo && (
        <div class="flex md:flex-row flex-col mx-auto text-center py-4 md:space-x-20 md:w-3/5 w-4/5  ">
          <div class="flex-1">
            <div class="font-bold mb-2 border-2 border-[#FBBB48] bg-[#000000] rounded-lg">
              <h1 className="text-sm font-bold text-white pt-4">Price</h1>
              <h2 className="text-4xl font-bold text-white mx-10 py-2">
                {tokenPrice ? tokenPrice : 0}
              </h2>
            </div>
          </div>
          <div class="flex-1">
            <div class="font-bold mb-2 border-2 border-[#FBBB48] bg-[#000000] rounded-lg">
              <h1 className="text-sm font-bold text-white pt-4"> In Wallet</h1>
              <h2 className="text-4xl font-bold text-white mx-10 py-2">
                {userInfo.balance ? Number(userInfo.balance).toFixed() : 0}
              </h2>
            </div>
          </div>
          <div class="flex-1">
            <div class="font-bold mb-2 border-2 border-[#FBBB48] bg-[#000000] rounded-lg">
              <h1 className="text-sm font-bold text-white pt-4"> Value</h1>
              <h2 className="text-4xl font-bold text-white mx-10 py-2">
                {userInfo.balance && tokenPrice
                  ? (Number(userInfo.balance) * tokenPrice).toFixed(2)
                  : 0}
              </h2>
            </div>
          </div>
        </div>
      )}
      <div className=" bg-[#000000] border-4  border-[#FBBB48] items-center justify-center md:w-3/5 w-4/5 mx-auto text-white mb-10 rounded-xl mt-10 pb-10">
        <div className="flex  p-4 space-x-4">
          <div className="flex-1 md:w-full w-4/5">
            <h1 className="md:text-6xl text-4xl font-bold">Interstellar</h1>
            <h2 className="md:text-lgs text-sm font-bold mt-4 text-center">
              Welcome Into Interstellar project, where tokens are your lifeline
              in a universe teetering on the edge of a black hole. You can
              navigate your own spacecraft, using tokens to fuel their journey
              and avoid the cosmic abyss. Engage in thrilling PvP battles, where
              successful attacks earn valuable HTWO tokens to escape the black
              hole's pull, while failed attempts bring you perilously closer to
              its event horizon. Join us in this epic adventure, where every
              decision could mean the difference between triumph and oblivion.
            </h2>
            <ul className="font-bold text-lg pt-10 text-center ">
              {rules.map((rule, index) => (
                <li key={index}>{rule}</li>
              ))}
            </ul>
            <p className="pt-5 font-bold">CA : {gameAddress}</p>
          </div>
        </div>

        {address &&
          !loading &&
          userInfo &&
          !userInfo.isDead &&
          userInfo.balance > 0 && (
            <div className="flex flex-col items-center">
              <div className=" border-4 border-[white p-2 bg-[#1D2635] rounded-xl">
                <img
                  src={play}
                  alt="Description"
                  className="h-60 w-64 object-cover mx-auto"
                />
                <button
                  className="mx-2 px-4 py-2 w-64 bg-[#FBBB48] font-bold text-black rounded hover:bg-white transition-colors text-xl"
                  onClick={() => handleAction("pvp")}
                >
                  PvP
                </button>
              </div>
            </div>
          )}

        {userInfo && userInfo.balance == 0 && (
          <div className="mx-auto">
            <p className="font-bold text-2xl py-4 text-red-600">
              You need token to play{" "}
            </p>
            <a
              href={`https://app.camelot.exchange/?token2=${gameAddress}`}
              target="_blank"
              className="mx-2 px-4 py-2 w-64 bg-[#FBBB48] font-bold text-black rounded hover:bg-white transition-colors text-xl"
            >
              Buy token
            </a>
          </div>
        )}

        {address &&
          !loading &&
          userInfo &&
          userInfo.isDead &&
          userInfo.balance > 0 && (
            <div className="flex flex-col items-center">
              <div className=" border-4 border-[white p-2 bg-[#1D2635] rounded-xl">
                <img
                  src={escapeDead}
                  alt="Description"
                  className="h-60 w-64 object-cover mx-auto"
                />
                <button
                  className="mx-2 px-4 py-2 w-64 bg-[#FBBB48] font-bold text-black rounded hover:bg-white transition-colors text-xl"
                  onClick={() => handleAction("transferAfterDeath")}
                >
                  Escape dead
                </button>
              </div>
            </div>
          )}

        {hash && (
          <div className="py-10 ">
            <a
              href={"https://arbiscan.io/tx/" + hash}
              target="_blank"
              className="text-sm font-bold underline hover:text-blue-400 md:w-full w-2/6"
            >
              {"https://arbiscan.io/tx/" + hash}
            </a>
          </div>
        )}

        {address &&
          !loading &&
          userInfo &&
          userInfo.userDataPvp &&
          userInfo.balance > 0 && (
            <div>
              <h1 className="text-2xl font-bold pt-10">Your last game</h1>
              <h1 className="text-lg font-bold pt-2">
                {timestampToDate(userInfo.userDataPvp[1].toNumber())} :{" "}
                {getActionText(userInfo.userDataPvp[0])}
              </h1>
            </div>
          )}

        {address && userInfo && userInfo.balance > 0 && (
          <div>
            <h1 className=" pt-10 text-5xl font-bold">Information</h1>
            <div>
              <p className=" pt-4 text-sm font-bold w-4/5 mx-auto">
                Every 6 days, the Mother Ship comes to the aid of spacecrafts
                still holding tokens. It prevents them from sinking into the
                depths of the universe by replenishing them with a portion of
                the fuel it possesses.
              </p>
            </div>
            <div className="flex flex-col md:flex-row mt-10 pb-10">
              <div className="border-4 w-60 flex items-center justify-center rounded-xl mx-auto ">
                <p className="text-center font-bold">Date of the death : </p>
                <p className="text-center font-bold">
                  {timestampToDate(userInfo.knowDeath)}
                </p>
              </div>

              <div className="flex flex-col mx-auto font-bold mt-10 text-black md:w-2/4 w-3/5 ">
                <input
                  type="text"
                  placeholder="ERC20 Address"
                  value={erc20Address}
                  onChange={(e) => setErc20Address(e.target.value)}
                  className="mb-2 px-4 text-xl text-center"
                />
                <div className="flex mb-2">
                  <input
                    type="number"
                    placeholder="Amount "
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className="mr-2 text-xl text-center md:w-full w-3/5"
                  />
                  <button
                    onClick={handleSetMax}
                    className="px-2 py-1 border text-white"
                  >
                    Max
                  </button>
                </div>
                <button
                  onClick={handleSubmit}
                  className="px-4 py-2 border text-white"
                >
                  Escape
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
